export const DEFAULT_PAYLOAD = {
  sys: {
    environment: {
      sys: {
        id: '[contentful_environment]'
      }
    },
    space: {
      sys: {
        id: '[contentful_space]'
      }
    }
  }
};
