export const callWebhook = async (
  webhookUrl: string,
  headerAuthorization: string,
  method: 'POST' | 'GET',
  payload?: string
) => {
  let config: any = {
    method,
    cache: 'no-cache'
  };

  let headers: { [key: string]: string } = {};

  if (headerAuthorization?.length)
    headers['Authorization'] = `Bearer ${headerAuthorization}`;

  if (method === 'POST' && payload) {
    config['body'] = payload;
  }

  config = { ...config, headers: headers };
  const result = await fetch(webhookUrl, config);
  try {
    // try parsing json before returning
    const parsedResult = await result.json();
    return parsedResult;
  } catch (error) {
    return result;
  }
};

export const isMasterEnv = (env: string) => {
  return env.toLowerCase().trim().indexOf('master') === 0;
};
