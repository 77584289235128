import React, { useEffect, useCallback, useState } from 'react';
import { PlainClientAPI } from 'contentful-management';
import { PageExtensionSDK } from '@contentful/app-sdk';
import {
  Heading,
  Subheading,
  Card,
  Note,
  Button,
  Modal,
  Flex,
  Notification,
  Badge,
  Text,
  Spinner
} from '@contentful/f36-components';
import {
  ExternalLinkIcon
} from '@contentful/f36-icons'
import { css } from 'emotion';
import { Message } from './Message';
import { callWebhook, isMasterEnv } from '../utils';
import { DEFAULT_PAYLOAD } from '../constants';

interface PageProps {
  sdk: PageExtensionSDK;
  cma: PlainClientAPI;
}

interface AppInstallationParameters {
  websiteUrl: string;
  siteId: string;
  webhookUrl: string;
  headerAuthorization: string;
  validWebsiteUrl: boolean;
  buttonTriggerLabel: string;
  appHeading: string;
  appSubHeading: string;
}

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { sdk: { parameters, ids } } = props;

  const [isModalShown, setIsModalShown] = useState(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isOk, setIsOk] = useState<boolean | undefined>(undefined);
  const [deployments, setDeployments] = useState<any>(undefined);
  const [deploymentsError, setDeploymentsError] = useState<boolean>(false);
  const [webhookName, setWebhookName] = useState<string | undefined>(undefined);
  const [branchToBuild, setBranchToBuild] = useState<string | undefined>(undefined);
  const [deployId, setDeployId] = useState<number | undefined>(undefined);
  const [deployTime, setDeployTime] = useState<string | undefined>(undefined);
  const [deployStatus, setDeployStatus] = useState<string | undefined>(undefined);
  const [isDeployComplete, setIsDeployComplete] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { websiteUrl, webhookUrl, siteId, appHeading, headerAuthorization, appSubHeading, buttonTriggerLabel } = parameters.installation as AppInstallationParameters;

  const paramsAreValid = true;

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const fetchDeployments = useCallback(async () => {
    const buildHooksPromise = callWebhook(
      `https://api.netlify.com/api/v1/sites/${siteId}/build_hooks`,
      headerAuthorization,
      'GET'
    );
    const deploymentsPromise = callWebhook(
      `https://api.netlify.com/api/v1/sites/${siteId}/deploys?per_page=40`,
      headerAuthorization,
      'GET'
    );

    const [buildHooks, deployments] = await Promise.all([buildHooksPromise, deploymentsPromise]);

    if (deployments.status === 401) {
      setDeploymentsError(true);
    }

    // find the current build hooke
    const buildHookTarget = buildHooks
      .filter((hook: any) => hook.url === webhookUrl);

    const [branchToBuild] = buildHookTarget?.map((hook: any) => hook.branch);

    const branchDeployments = deployments.filter((deployment: any) => deployment.branch === branchToBuild);
    setBranchToBuild(branchToBuild);
    setWebhookName(buildHookTarget?.[0]?.title);
    setDeployments(branchDeployments.slice(0, 20))
  }, []);

  const fetchDeployStatus = useCallback(async (deployId) => {
    let continuePolling = true;

    while (continuePolling) {
      const data = await callWebhook(
        `https://api.netlify.com/api/v1/deploys/${deployId}`,
        headerAuthorization,
        'GET'
      );
      if (data?.state === 'ready') {
        if (!data?.error_message) {
          Notification.success('Deployment complete!')
            .finally(() => setIsOk(undefined));
          setDeployStatus(data?.state);
          setIsDeployComplete(data?.state === 'ready');
          continuePolling = false;
        } else {
          Notification.error('Deployment error')
            .finally(() => setIsOk(undefined));
          setDeployStatus(data?.state);
          setIsDeployComplete(data?.state === 'ready');
          setError(data?.error_message);
          continuePolling = false;
        }
      }
      setDeployStatus(data?.state);
      await sleep(5000);
    }
  }, [])

  useEffect(() => {
    fetchDeployments().catch(console.error);
  }, [deployStatus])

  useEffect(() => {
    if (deployId && !isDeployComplete) {
      fetchDeployStatus(deployId)
        .catch(console.error);
    }
  }, [fetchDeployStatus, deployId])

  const deploy = async () => {
    if (!webhookUrl) {
      return;
    }
    setError(undefined);
    setIsBusy(true);

    const environment = isMasterEnv(ids.environment)
      ? 'master'
      : ids.environment.trim();

    const defaultPayload = { ...DEFAULT_PAYLOAD };
    defaultPayload.sys.environment.sys.id = environment;
    defaultPayload.sys.space.sys.id = ids.space;

    try {
      const triggeredBuild = await callWebhook(
        webhookUrl,
        headerAuthorization,
        'POST',
        JSON.stringify(defaultPayload)
      );
      if (triggeredBuild.status === 200) {
        // if the webhookURL is a custom build hook, we need to
        // explicitly fetch the webhook data and latest build id

        // fetch latest 5 deployments
        const deployments = await callWebhook(
          `https://api.netlify.com/api/v1/sites/${siteId}/deploys?per_page=80`,
          headerAuthorization,
          'GET'
        );

        // find the deployment that has been triggered
        const triggeredDeploy = deployments.find((deployment: any) => deployment.branch === branchToBuild);

        // set deploy id
        if (triggeredDeploy.id) {
          setDeployId(triggeredDeploy.id);
          setDeployTime(triggeredDeploy.created_at);
          setIsDeployComplete(triggeredDeploy.state === 'ready');
          setIsOk(true);
        }
      }
      if (!triggeredBuild?.deploy_id && triggeredBuild.status !== 200) {
        setIsOk(false)
        setError(`${triggeredBuild.status}: ${triggeredBuild.statusText}`);
      }
      if (triggeredBuild?.deploy_id) {
        setDeployId(triggeredBuild.deploy_id);
        setDeployTime(triggeredBuild.created_at);
        setIsOk(true);
      }
    } catch (error) {
      console.error(error);
      setIsOk(false);
    }
    setIsBusy(false);
  };

  if (!isBusy && isOk === false) {
    Notification.error('Last deploy request failed.', {
      title: error || 'Building & Deploying site failed.'
    }).finally(() => {
      setIsOk(undefined);
    });
  }

  return paramsAreValid ? (
    <div className={css({ margin: '80px' })}>
      <Flex justifyContent="space-around" fullWidth>
        {/* DEPLOY BUTTON */}
        <Flex as="div" fullWidth flexDirection="column">
          <Heading>{appHeading || 'Build webhook'}</Heading>
          <Subheading>{appSubHeading}</Subheading>
          {webhookName && branchToBuild && (
            <Flex alignItems="baseline" gap="spacingS" marginBottom="spacingS" flexDirection="column">
              <Text fontColor="blue500" fontSize="fontSizeS">
                Using webhook:
                <Text fontColor="blue500" fontSize="fontSizeS" as="i"> {webhookName} </Text>
              </Text>
              <Text fontColor="blue500" fontSize="fontSizeS">
                against branch:
                <Text fontColor="blue500" fontSize="fontSizeS" as="i"> {branchToBuild}</Text>
              </Text>
            </Flex>
          )}
          <Flex
            as="div"
            justifyContent="flex-start"
            alignItems="center"
            marginBottom="spacingS"
          >
            <Flex>
              <Button
                variant={"primary"}
                onClick={() => {
                  Notification.closeAll();
                  setIsModalShown(true);
                }}
              >
                {buttonTriggerLabel}
              </Button>
              <Button
                onClick={() => window.open(websiteUrl, '_blank')}
                variant="transparent"
                endIcon={<ExternalLinkIcon />}
              >
                View website
              </Button>
            </Flex>
          </Flex>
          {isBusy && (
            <Message text="Sending build request" spinner />
          )}
          {deployId && deployTime && (
            <Flex
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection="column"
              gap="spacingS"
              marginTop="spacingS"
            >
              {!isDeployComplete && !error && (
                <Message text="Checking for deploy status" spinner />
              )}
              {isDeployComplete && !error && (
                <Message text="Deploy complete!" />
              )}
              {!!error && (
                <Message text={`Error: ${error}`} />
              )}
              {!error && (
                <Text>Deploy status: {isDeployComplete ? (
                  <Badge variant="positive">{deployStatus}</Badge> ) : (<Badge variant="warning">{deployStatus}</Badge> )}
                </Text>
              )}
              {!!error && (
                <Text>Deploy status:
                  <Badge variant="negative">{deployStatus}</Badge>
                </Text>
              )}
                <Text>
                  Triggered at: <Badge variant="primary">{new Date(deployTime).toLocaleTimeString()}</Badge>
                </Text>
                <Text>
                  Deploy ID: <Badge variant="secondary">{deployId}</Badge>
                </Text>
            </Flex>
          )}
        </Flex>

        {/* DEPLOYMENT HISTORY */}
        <Flex flexDirection="column" fullWidth fullHeight gap="spacingS">
          <Heading>Deployment history</Heading>
          {!deployments && !deploymentsError && <Spinner />}
          {!deployments && deploymentsError && (
            <Message text="Cannot fetch deployment history - please add site ID and authorisation token to app config" />
          )}
          {deployments && deployments?.map((deployment: any) => (
            <Flex gap="spacingS">
              <Flex flexDirection="column" fullWidth>
                {deployment.error_message && (
                  <Text>
                    Deploy status: <Badge variant={'negative'} size="small">{deployment.state}</Badge>
                  </Text>
                )}
                {!deployment.error_message && (
                  <Text>
                    Deploy status: <Badge variant={deployment?.state === 'ready' ? 'positive' : 'warning'} size="small">{deployment.state}</Badge>
                  </Text>
                )}
                {deployment.published_at ? (
                  <>
                    <Text>
                      Published at: <Badge variant="primary" size="small">{new Date(deployment.published_at).toLocaleString()}</Badge>
                    </Text>
                    <Text>
                      Run time: <Badge variant="primary" size="small">{deployment.deploy_time} seconds</Badge>
                    </Text>
                  </>
                ) : (
                  <Text>
                    Triggered at: <Badge variant="primary" size="small">{new Date(deployment.created_at).toLocaleString()}</Badge>
                  </Text>
                )}
                <Text>
                  Deploy ID: <Badge variant="secondary" size="small">{deployment.id}</Badge>
                </Text>
              </Flex>
              {deployment.screenshot_url && (
                <Flex>
                  <img src={deployment.screenshot_url} width={100} />
                </Flex>
              )}
              {!deployment.screenshot_url && (
                <Flex>
                  <div style={{ width: 85, background: 'lightgrey' }} />
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Modal
        title="Centered modal"
        isShown={isModalShown}
        onClose={() => setIsModalShown(false)}
      >
        {() => (
          <React.Fragment>
            <Modal.Header title={appHeading} />
            <Modal.Content>
              Click &quot;{buttonTriggerLabel}&quot; to deploy your site now.
              <br /> Please note that deployment can take a few minutes.
            </Modal.Content>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginBottom="spacingM"
              marginLeft="spacingM"
              marginRight="spacingM"
            >
              <Button
                variant="positive"
                onClick={() => {
                  setIsModalShown(false);
                  deploy();
                  Notification.success(
                    'Please note that it could take a few minutes before you see changes.',
                    { title: 'Building & Deploying site...' }
                  );
                }}
              >
                {buttonTriggerLabel}
              </Button>
              <Button variant="secondary" onClick={() => setIsModalShown(false)}>
                Cancel
              </Button>
            </Flex>
          </React.Fragment>
        )}
      </Modal>
    </div>
  ) : (
    <Card style={{ margin: '1em' }}>
      <Note variant="primary">
        Please define the
        required installation properties in your app configuration.
      </Note>
    </Card>
  );
};

export default Page;
