import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import {
  Heading,
  Form,
  Paragraph,
  FormControl,
  TextInput,
  Flex,
  Button,
} from '@contentful/f36-components';
import { css } from 'emotion';

export interface AppInstallationParameters {
  websiteUrl: string;
  webhookUrl: string;
  siteId: string;
  headerAuthorization: string;
  validWebsiteUrl: boolean;
  validWebhook: boolean;
  buttonTriggerLabel: string;
  appHeading: string;
  appSubHeading: string;
  appConfirmMessage: string;
}

interface ConfigScreenProps {
  sdk: AppExtensionSDK;
  cma: PlainClientAPI;
}

type ParameterKeys = keyof AppInstallationParameters;

const ConfigScreen = (props: ConfigScreenProps) => {
  const [parameters, setParameters] = useState<AppInstallationParameters>({
    websiteUrl: '',
    webhookUrl: '',
    siteId: '',
    headerAuthorization: '',
    validWebsiteUrl: true,
    validWebhook: true,
    buttonTriggerLabel: 'Deploy Site',
    appHeading: 'Website Deployment',
    appSubHeading: '',
    appConfirmMessage: ''
  });

  const onConfigure = useCallback(async () => {
    const currentState = await props.sdk.app.getCurrentState();

    let valid = true;

    if (
      !parameters.websiteUrl ||
      !parameters.websiteUrl.startsWith('http')
    ) {
      const newParameters = {
        ...parameters,
        validWebsiteUrl: false
      };
      setParameters(newParameters);
      valid = false;
    }

    if (
      !parameters.webhookUrl ||
      !parameters.webhookUrl.startsWith('https://')
    ) {
      const newParameters = {
        ...parameters,
        validWebhook: false
      };
      setParameters(newParameters);
      valid = false;
    }

    if (!valid) {
      props.sdk.notifier.error(
        'Please review the errors in the form'
      );
      return false;
    }

    return {
      parameters,
      targetState: currentState,
    };
  }, [parameters, props.sdk]);

  useEffect(() => {
    props.sdk.app.onConfigure(() => onConfigure());
  }, [props.sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      const currentParameters: AppInstallationParameters | null =
        await props.sdk.app.getParameters();

      if (currentParameters) {
        setParameters(currentParameters);
      }
      props.sdk.app.setReady();
    })();
  }, [props.sdk]);

  const updateWebsiteUrl = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement;
    const newParameters = {
      ...parameters,
      websiteUrl: target.value,
      validWebsiteUrl: true
    };
    setParameters(newParameters);
  };

  const updateWebhookUrl = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement;
    const newParameters = {
      ...parameters,
      webhookUrl: target.value,
      validWebhook: true
    };
    setParameters(newParameters);
  };

  const onInputChange = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement;
    const { name, value } = target;

    const newParameters = {
      ...parameters,
      [name as ParameterKeys]: value
    };
    setParameters(newParameters);
  };

  // Validation
  const validateWebsiteUrl = (): void => {
    const myCurrentStateParams = { ...parameters };
    if (!myCurrentStateParams.websiteUrl.startsWith('http')) {
      const newParameters = { ...myCurrentStateParams, validWebsiteUrl: false };
      setParameters(newParameters);
    }
  };

  const validateWebhookUrl = (): void => {
    const myCurrentStateParams = { ...parameters };
    if (!myCurrentStateParams.webhookUrl.startsWith('https://')) {
      const newParameters = { ...myCurrentStateParams, validWebhook: false };
      setParameters(newParameters);
    }
  };

  return (
    <Flex className={css({ margin: '80px' })}>
      <Form>
        <Heading>Netlify - Build Webhook</Heading>
        <Paragraph>
          This is your config page for setting up a Netlify webhook trigger. <br />{' '}
        </Paragraph>

        <Flex gap="spacingM">
          <FormControl>
            <FormControl.Label>Application Heading</FormControl.Label>
            <TextInput
              name="appHeading"
              id="appHeading"
              value={parameters.appHeading}
              onChange={onInputChange}
            />
            <FormControl.HelpText>Heading for the application</FormControl.HelpText>
          </FormControl>
          <FormControl>
            <FormControl.Label>Application Sub Heading</FormControl.Label>
            <TextInput
              name="appSubHeading"
              id="appSubHeading"
              value={parameters.appSubHeading}
              onChange={onInputChange}
            />
            <FormControl.HelpText>Sub Heading for the application</FormControl.HelpText>
          </FormControl>
        </Flex>
        <Flex gap="spacingM" marginBottom="spacingXl" alignItems="center">
          <FormControl>
            <FormControl.Label>Button Label</FormControl.Label>
            <TextInput
              name="buttonTriggerLabel"
              id="buttonTriggerLabel"
              value={parameters.buttonTriggerLabel}
              onChange={onInputChange}
            />
            <FormControl.HelpText>Label for the trigger button</FormControl.HelpText>
          </FormControl>
          <Button variant="primary" className={css({ marginBottom: '24px' })}>
            {parameters.buttonTriggerLabel || 'Deploy Site'}
          </Button>
        </Flex>
        <FormControl
          isRequired
          isInvalid={!parameters.validWebsiteUrl}
        >
          <FormControl.Label>Site URL</FormControl.Label>
          <TextInput
            value={parameters.websiteUrl}
            onChange={updateWebsiteUrl}
            onBlur={validateWebsiteUrl}
          />
          {!parameters.validWebsiteUrl && (
            <FormControl.ValidationMessage>Please provide a valid URL (It should start with http)</FormControl.ValidationMessage>
          )}
          <FormControl.HelpText>Enter in the URL to your site to view.</FormControl.HelpText>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!parameters.validWebhook}
        >
          <FormControl.Label>Netlify webhook Url</FormControl.Label>
          <TextInput
            value={parameters.webhookUrl}
            onBlur={validateWebhookUrl}
            placeholder="eg: https://api.netlify.com/build_hooks/623bc37a1b6623bc37a1b6"
            onChange={updateWebhookUrl}
          />
          {!parameters.validWebhook && (
            <FormControl.ValidationMessage>Please provide a valid URL (It should start with https://)</FormControl.ValidationMessage>
          )}
          <FormControl.HelpText>{"The webhook URL of the Netlify site - used to trigger the build"}</FormControl.HelpText>
        </FormControl>
        <FormControl>
          <FormControl.Label>Site ID</FormControl.Label>
          <TextInput
            name="siteId"
            id="siteId"
            value={parameters.siteId}
            placeholder="eg: 32a65f36-cf40-4062-cf40-32a65f36"
            onChange={onInputChange}
          />
          <FormControl.HelpText>The Netlify site ID - used to check deployment status</FormControl.HelpText>
        </FormControl>
        <FormControl>
          <FormControl.Label>Authorization Token</FormControl.Label>
          <TextInput
            name="headerAuthorization"
            value={parameters.headerAuthorization}
            placeholder="eg: xyz123"
            onChange={onInputChange}
            type="password"
          />
          <FormControl.HelpText>Authorization token for the Netlify API - used to check deployment status</FormControl.HelpText>
        </FormControl>
      </Form>
    </Flex>
  );
};

export default ConfigScreen;
